<template>
  <div>
    <div class="title_box_main">
      <div class="cionsv"></div>
      <div class="titles-label">{{ title }}</div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      default: "2023年度专业科目",
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped lang="scss">
.cionsv {
  width: 4px;
  height: 16px;
  border-radius: 35px;
  background: #710605;
  margin-right: 5px;
}
.titles-label {
  font-size: 16px;
  color: #21252e;
}
.title_box_main {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
</style>
