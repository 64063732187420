import request from "@/utils/request";

// 公需课统计
export const getPublicStatistics = (data) => {
  return request({
    url: "/foreground/course/getGongXuTask",
    method: "GET",
    data,
  });
};
// 获取公需课列表
export const getCourseList = (data) => {
  return request({
    url: "/foreground/course/gongXu/userCourseList",
    method: "GET",
    data,
  });
};
// 获取公需课考试列表
export const getGongXuUserCourseList = (data) => {
  return request({
    url: "/foreground/course/exam/gongXu/userCourseList",
    method: "GET",
    data,
  });
};
// 获取公需课详情
export const getGongXuUserCourseById = (data) => {
  return request({
    url: "/foreground/testTitle/gongXuUserCourseById",
    method: "GET",
    data,
  });
};
// 获取公需课详情
export const getGongXuProcessUserCourseList = (data) => {
  return request({
    url: "/foreground/course/gongXu/process/userCourseList",
    method: "GET",
    data,
  });
};
