<template>
  <div class="m_professional">
    <m-title title="2023年度公需科目" />
    <div class="statistics">
      <div class="statistics_card right">
        <div>
          <van-circle
            :size="40"
            :stroke-width="150"
            color="#FFFFFF"
            layer-color="rgba(255, 255, 255, 0.4)"
            :rate="staticsInfo.zongXueBaiFenBai"
            :speed="100"
          />
        </div>

        <div style="margin-left: 5px">
          <div style="font-size: 16px">
            {{ staticsInfo.zongXueBaiFenBai + "%" }}
          </div>
          <div style="font-size: 12px; margin-top: 5px">学习进度</div>
        </div>
      </div>
      <div class="statistics_box">
        <div class="statistics_card left">
          <div style="font-size: 12px; margin-top: 5px">已获总学时</div>
          <div style="font-size: 16px">{{ staticsInfo.zongXue }}</div>
        </div>
        <div class="statistics_card left">
          <div style="font-size: 12px; margin-top: 5px">学习总时长</div>
          <div style="font-size: 16px">{{ staticsInfo.zongTime }}</div>
        </div>
      </div>
    </div>
    <van-tabs
      v-model="active"
      color="#E3211F"
      :line-height="4"
      @click="tabClick"
    >
      <van-tab title="视频课程"></van-tab>
      <van-tab title="文章资讯"></van-tab>
    </van-tabs>
    <div class="courseList">
      <capsule-tab @click="radioClick" :list="List" />
      <radio-tab @click="statusClick" :list="List2" />
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div class="listvideo" v-if="active === 0">
            <div v-for="(item, index) in videoList" :key="index">
              <div
                @click="
                  keepAliveNavigation(
                    `/openPlayer?courseContId=${item.courseContId}`
                  )
                "
                class="item"
              >
                <div class="left">
                  <div class="title">{{ item.courseContName }}</div>
                  <div class="time">
                    <svg-icon
                      icon="icon-keshi"
                      color="#1677FE"
                      style="font-size: 16px"
                    />
                    <div class="videoTime">{{ item.totalSeconds }} 秒</div>
                  </div>
                </div>
                <div class="right">
                  <img class="cover" :src="item.videoImg" alt="" srcset="" />
                  <div class="">
                    <div class="">
                      <van-tag
                        class="tag"
                        type="success"
                        :color="item.isWanCheng ? '#549A54' : '#B50413'"
                        text-color="#ffffff"
                      >
                        {{ item.isWanCheng ? "完成" : "未完成" }}</van-tag
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="list" v-if="active === 1">
            <div
              @click="
                keepAliveNavigation(
                  `/imageAndText?courseContId=${item.courseContId}`
                )
              "
              class="item"
              v-for="(item, index) in dataList"
              :key="index"
            >
              <div class="title">
                <div>
                  <van-tag
                    class="tag"
                    type="success"
                    :color="item.isWanCheng ? '#549A54' : '#B50413'"
                    text-color="#ffffff"
                  >
                    {{ item.isWanCheng ? "完成" : "未完成" }}</van-tag
                  >
                </div>
                <div class="titlename">{{ item.courseContName }}</div>
              </div>
              <div class="abstract">{{ item.introduction }}</div>
              <div class="bottoms">
                <div class="textlength">
                  <svg-icon icon="icon-zishu" color="#1677FE" />
                  {{ item.wordCount }}字
                </div>
                <div class="timelength">
                  预计阅读时长：{{ item.totalSeconds }}秒
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>
<script>
import MTitle from "@/components/m_title.vue";
import SvgIcon from "@/components/svgIcon.vue";
import CapsuleTab from "@/components/capsuleTab.vue";
import RadioTab from "@/components/radioTab.vue";
import {
  getCourseList,
  getPublicStatistics,
} from "@/api/publicDemandController";
import * as storage from "@/utils/storage";

export default {
  name: "m_professional",
  components: {
    RadioTab,
    CapsuleTab,
    SvgIcon,
    MTitle,
  },
  data() {
    return {
      active: 0,
      staticsInfo: {},
      loading: false,
      finished: false,
      refreshing: false,
      dataList: [],
      videoList: [],
      selectTab: 0,
      currentRate: 0,
      rate: 80,
      List: [
        {
          label: "思想政治",
          value: 1,
        },
        {
          label: "法律法规",
          value: 2,
        },
        {
          label: "职业道德",
          value: 3,
        },
        {
          label: "诚信自律",
          value: 4,
        },
      ],
      List2: [
        {
          label: "全部",
          value: 2,
        },
        {
          label: "未完成",
          value: 0,
        },
        {
          label: "已完成",
          value: 1,
        },
      ],
      courseParams: {
        pageNo: 1,
        pageSize: 8,
        speciesEnum: 0,
        classifyEnum: 1,
        statusEnum: 2,
        total: 0,
      },
    };
  },
  created() {
    this.publicStatics();
  },
  activated() {
    this.inspectRefresh(this.handleRefresh);
  },
  methods: {
    // 获取公需课统计
    publicStatics() {
      const that = this;
      getPublicStatistics({ trainingPlanId: storage.getStore("yearId") }).then(
        (res) => {
          that.staticsInfo = res.data;
        }
      );
    },
    tabClick(name) {
      this.courseParams.speciesEnum = name;
      this.courseParams.pageNo = 1;
      this.onRefresh();
    },
    // 切换状态
    statusClick(data) {
      this.courseParams.statusEnum = data.value;
      this.courseParams.pageNo = 1;
      this.onRefresh();
    },
    // 切换小类
    radioClick(data) {
      this.courseParams.classifyEnum = data.value;
      this.courseParams.pageNo = 1;
      this.onRefresh();
    },
    // 刷新
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.courseParams.pageNo = 1;
      this.onLoad();
    },
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求、
      const that = this;
      if (that.refreshing) {
        that.videoList = [];
        that.dataList = [];
        this.refreshing = false;
      }
      getCourseList({
        trainingPlanId: storage.getStore("yearId"),
        ...that.courseParams,
      }).then((res) => {
        if (that.courseParams.pageNo === 1) {
          that.videoList = [];
          that.dataList = [];
        }
        that.courseParams.pageNo++;
        res.data.data.map((item) => {
          if (that.courseParams.speciesEnum === 0) {
            if (item.videoUrl) {
              const url = JSON.parse(item.videoUrl)[0].url || "";
              item.videoImg = url
                .replace(".mp4", ".jpg")
                .replace(".MP4", ".jpg");
            } else {
              item.videoImg = "";
            }
            that.videoList.push(item);
          } else {
            that.dataList.push(item);
          }
        });
        that.loading = false;
        if (that.courseParams.speciesEnum === 0) {
          if (that.videoList.length >= res.data.totalCount) {
            that.finished = true;
          }
        } else {
          if (that.dataList.length >= res.data.totalCount) {
            that.finished = true;
          }
        }
      });
    },
    handleRefresh() {
      console.log("我刷新咯");
      this.publicStatics();
    },
  },
};
</script>
<style scoped lang="scss">
/deep/ .van-tabs__nav {
  background: transparent;
}
/deep/ .van-tab {
  flex: none;
}
/deep/ .van-tab__text {
  font-size: 16px;
}
.m_professional {
  height: 100%;
  width: 100%;

  .statistics {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    margin: 10px auto;
    .statistics_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .statistics_card {
      padding: 10px 15px;
      background: #b50413;
      border-radius: 10px;
      color: #ffffff;
      width: calc(100% - 30px);
      margin-bottom: 4px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .left {
      background: rgba(251, 154, 162, 0.2);
      text-align: center;
      color: #505363;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .right {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-content: center;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .courseList {
    background: #ffffff;
    border-radius: 8px;
    padding: 12px 10px;
    margin: 10px auto;

    .list {
      .item {
        padding: 12px 15px;
        background: #f4f6f8;
        border-radius: 4px;
        margin-bottom: 12px;

        .title {
          width: 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: flex-start;
          align-items: center;

          .tag {
            padding: 2px 4px;
            white-space: nowrap;
          }

          .titlename {
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            font-size: 14px;
            margin-left: 5px;
          }
        }

        .abstract {
          color: #818496;
          font-size: 12px;
          margin-top: 5px;
          display: -webkit-box;
          overflow: hidden;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin: 10px auto;
        }

        .bottoms {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: space-between;
          align-items: center;
          margin-top: 5px;
          .timelength {
            color: #818496;
            font-size: 12px;
            display: flex;
            align-items: center;
          }
          .textlength {
            color: #818496;
            font-size: 12px;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .listvideo {
      .item {
        padding: 12px 15px;
        background: #f4f6f8;
        border-radius: 4px;
        margin-bottom: 12px;
        display: flex;
        box-sizing: border-box;
        .left {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-content: center;
          justify-content: space-between;
          align-items: flex-start;
          box-sizing: border-box;
          width: calc(100% - 110px);
          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .time {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-content: center;
            justify-content: flex-start;
            align-items: center;
            .videoTime {
              color: #818496;
              font-size: 12px;
              margin-left: 2px;
            }
          }
        }
        .right {
          width: 120px;
          height: 80px;
          position: relative;
          margin-left: 10px;
          min-width: 120px;
          .cover {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .tag {
            position: absolute;
            top: 2px;
            right: 2px;
            padding: 2px 4px;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
