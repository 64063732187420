<template>
  <div class="radio-box">
    <div class="radioTab">
      <div
        class="radio-tab-tag"
        :class="index === selectValue ? 'radio-tab-tag-ed' : ''"
        v-for="(item, index) in list"
        :key="index"
        @click="radioSelect(item, index)"
      >
        {{ item.label }}
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      selectValue: this.value,
    };
  },
  methods: {
    radioSelect(item, index) {
      this.selectValue = index;
      this.$emit("click", item, index);
    },
  },
};
</script>

<style scoped lang="scss">
.radio-box {
  border-radius: 100px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin: 12px 0;
  background: #f5f5f5;
  overflow-x: auto;
}
.radio-box::-webkit-scrollbar {
  display: none;
}
.radioTab {
  width: max-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;

  .radio-tab-tag {
    padding: 6px 12px;
    width: 81px;
    box-sizing: border-box;
    color: #818496;
    cursor: pointer;
    text-align: center;
    border-radius: 100px;
    overflow-x: auto;
  }

  .radio-tab-tag-ed {
    background: #ba2926;
    color: #ffffff;
    transition: all 0.8s ease-out;
  }
}
</style>
