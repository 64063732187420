<template>
  <div class="radioBodx">
    <div class="radioTab">
      <div
        class="radio-tab-tag"
        :class="index === selectValue ? 'radio-tab-tag-ed' : ''"
        v-for="(item, index) in list"
        :key="index"
        @click="radioSelect(item, index)"
      >
        {{ item.label }}
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      selectValue: this.value,
    };
  },
  methods: {
    radioSelect(item, index) {
      this.selectValue = index;
      this.$emit("click", item, index);
    },
  },
};
</script>

<style scoped lang="scss">
.radioBodx {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin: 12px 0;
}
.radioTab {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  .radio-tab-tag {
    padding: 4px 12px;
    border-radius: 4px;
    background: rgba(186, 41, 38, 0.02);
    box-sizing: border-box;
    border: 1px solid #ba2926;
    margin-right: 10px;
    color: #ba2926;
    cursor: pointer;
    margin-bottom: 5px;
  }

  .radio-tab-tag-ed {
    background: #ba2926;
    color: #ffffff;
  }
}
</style>
